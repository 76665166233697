// project imports
import MinimalLayout from 'layout/MinimalLayout';
import UnderConstruction from 'views/pages/maintenance/under-construction';

const MaintenanceRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/under-construction',
            element: <UnderConstruction />
        }
    ]
};

export default MaintenanceRoutes;
