// material-ui
import React, { useEffect, useState } from 'react';
import { IEvent } from 'types/event';
import { Grid, TextField } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useDispatch, useSelector } from 'store';
import { updatePlanification } from 'store/slices/event';

interface Props {
    updateActionStep: (valid?: boolean) => void;
}

const EventInfo: React.FC<Props> = ({ updateActionStep }: Props) => {
    const [details, setDetails] = useState({} as IEvent);
    let event = useSelector((state) => state.event.planification) as IEvent;
    const dispatch = useDispatch();

    useEffect(() => {
        setDetails(event);
        if (!event) {
            console.log(details);
        }
    }, [event]);

    const update = (isValid: boolean) => {
        updateActionStep(isValid);
        if (isValid) {
            dispatch(updatePlanification(event));
        }
    };

    return (
        <MainCard className="main-box">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id="title"
                        fullWidth
                        label="Title"
                        value={`${event?.title}`}
                        onChange={(e) => {
                            if (e.target.value) {
                                event = {
                                    ...event,
                                    title: e.target.value
                                };
                                update(true);
                            } else {
                                update(false);
                            }
                        }}
                        helperText=""
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="more"
                        multiline
                        rows={3}
                        fullWidth
                        label="Description / Price Information"
                        value={`${event?.more}`}
                        onChange={(e) => {
                            if (e.target.value) {
                                event = {
                                    ...event,
                                    more: e.target.value
                                };
                                update(true);
                            } else {
                                update(false);
                            }
                        }}
                        helperText=""
                    />
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default EventInfo;
