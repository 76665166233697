// material-ui
import {
    alertClasses,
    Avatar,
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    OutlinedInput,
    Paper,
    Radio,
    RadioGroup,
    Stack
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IEvent, IEventMember } from 'types/event';
import MainCard from 'ui-component/cards/MainCard';
import SearchIcon from '@mui/icons-material/Search';
import EventService from '../../../../../services/events.service';
import { useDispatch, useSelector } from 'store';
import { updatePlanification } from 'store/slices/event';

interface Props {
    updateActionStep: (valid?: boolean) => void;
}

const EventParcicipant: React.FC<Props> = ({ updateActionStep }: Props) => {
    const dispatch = useDispatch();
    const event = useSelector((state) => state.event.planification) as IEvent;
    const [groupType, setGroupType] = useState('ALL');
    const [members, setMembers] = useState([] as IEventMember[]);
    const [filteredMembers, setFilteredMembers] = useState([] as IEventMember[]);
    const [checked, setChecked] = React.useState([] as string[]);

    useEffect(() => {
        const inviteType = event.inviteType || 'ALL';
        const participants = event.invitations || ([] as IEventMember[]);
        fetchMembers().then((recentAllMembers) => {
            setGroupType(inviteType);
            if (participants.length > 0) {
                const selection = participants!.map((p: IEventMember) => p.uid) as string[];
                setChecked(selection);
            }
            if (inviteType === 'ALL') {
                checkAll(recentAllMembers);
            }
        });
    }, []);

    const checkAll = (all: IEventMember[]) => {
        // debugger; // eslint-disable-line
        const newChecked = all.map((member) => member.uid);
        setChecked(newChecked);
        const participants = [] as IEventMember[];
        all.map((mem) => {
            participants.push({
                uid: mem.uid,
                displayName: mem.displayName,
                avatar: mem.avatar
            } as IEventMember);
        });
        dispatch(
            updatePlanification({
                ...event,
                inviteType: 'ALL',
                invitations: participants
            })
        );
    };
    const fetchMembers = () => {
        return new Promise<IEventMember[]>((resolve, reject) => {
            EventService.getAllMembers()
                .then((membersData) => {
                    setMembers(membersData);
                    setFilteredMembers(membersData);
                    resolve(membersData as IEventMember[]);
                })
                .catch((e) => {
                    console.error(e);
                    reject();
                });
        });
    };

    const getSelectedMembers = (list: string[]) => {
        const temp: IEventMember[] = [];
        list.map((uid) => {
            const mem = members.find((me) => me.uid === uid) as IEventMember;
            if (mem) {
                const newOne = {
                    uid: mem.uid,
                    displayName: mem.displayName,
                    avatar: mem.avatar
                } as IEventMember;
                temp.push(newOne);
            }
        });
        return temp;
    };

    const onFilter = (e: any) => {
        const f = members.filter((m) => {
            const name = (m.displayName || '').toLowerCase();
            return name.indexOf(e.target.value.toLowerCase()) > -1;
        });
        setFilteredMembers(f as []);
    };

    const handleToggle = (value: IEventMember) => () => {
        const selected = value.uid;
        const currentIndex = checked.indexOf(selected);
        let newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(selected);
        } else {
            newChecked = checked.filter((m) => m !== selected);
        }
        setChecked(newChecked);
        if (newChecked.length > 0) {
            updateActionStep(true);
            const participants = getSelectedMembers(newChecked);
            dispatch(
                updatePlanification({
                    ...event,
                    inviteType: groupType,
                    invitations: participants
                })
            );
        } else {
            updateActionStep(false);
        }
    };

    return (
        <MainCard className="main-box" sx={{ height: '400px' }}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <RadioGroup
                        value={groupType}
                        onChange={(newValue) => {
                            setGroupType(newValue.target.value || 'ALL');
                            dispatch(
                                updatePlanification({
                                    ...event,
                                    inviteType: newValue.target.value
                                })
                            );
                            if (event.inviteType !== 'ALL' && checked.length === 0) {
                                updateActionStep(false);
                            } else {
                                updateActionStep(true);
                            }
                        }}
                    >
                        <FormControlLabel control={<Radio />} label={`Full House (  ${members.length}  )`} value="ALL" />
                        <FormControlLabel control={<Radio />} label="Selected Members" value="SELECTION" />
                    </RadioGroup>
                </Grid>
                <Grid item xs={8}>
                    <Box className="participants-box" sx={{ width: '100%', padding: 0, minHeight: 350, bgcolor: 'background.paper' }}>
                        <Stack>
                            <FormControl sx={{ m: 1 }}>
                                <InputLabel htmlFor="outlined-search-member" />
                                <OutlinedInput
                                    id="outlined-search-member"
                                    size="small"
                                    onChange={onFilter}
                                    startAdornment={<SearchIcon />}
                                    placeholder="Search members"
                                />
                            </FormControl>
                        </Stack>
                        <Divider />
                        <List
                            dense
                            sx={{ width: '100%', bgcolor: 'background.paper', overflow: 'auto', maxHeight: 300, position: 'relative' }}
                        >
                            {filteredMembers.map((member: IEventMember, index: any) => {
                                const labelId = `checkbox-list-secondary-label-${index}`;
                                return (
                                    <ListItem
                                        key={index}
                                        secondaryAction={
                                            <Checkbox
                                                edge="end"
                                                disabled={groupType === 'ALL'}
                                                onChange={handleToggle(member)}
                                                checked={checked.indexOf(member.uid) !== -1}
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton onClick={handleToggle(member)} disabled={groupType === 'ALL'}>
                                            <ListItemAvatar>
                                                <Avatar alt={member.displayName} src={member.avatar} />
                                            </ListItemAvatar>
                                            <ListItemText id={labelId} primary={member.displayName} secondary={member.email} />
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Box>
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default EventParcicipant;
