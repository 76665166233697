import { Dayjs } from 'dayjs';

export interface EventProps {
    selection: IEvent | undefined;
    planification?: IEvent;
    reloadEvents: boolean;
}
export enum IEventType {
    NONE = '',
    CHEERSCHALLENGE = 'cheers-challenge',
    NOMINATOIN = 'nominations',
    TAKEDESITION = 'take-desition'
}
export enum IEventInviteType {
    FULLHOUSE = 'ALL',
    SELECTION = ''
}
export interface IEventSchedule {
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    duration: number;
}
export interface IEvent {
    uid?: string;
    title: string;
    type?: IEventType;
    inviteType: string;
    more?: string;
    invitationType?: string;
    invitations?: IEventMember[];
    inscriptionStart: IEventDate | any;
    inscriptionEnd: IEventDate | any;
    pollStart: IEventDate | any;
    pollEnd: IEventDate | any;
    organizedBy?: IEventMember;
    galleria?: IEventAsset[];
}
export interface IEventStage extends IEvent {
    stage: string;
    date: any;
}
export interface IEventMember {
    uid: string;
    displayName: string;
    email?: string;
    phone?: string;
    avatar?: string;
}
export interface IEventAsset {
    mediaSrc?: string;
    caption?: string;
    postedBy: string;
    postedByName: string;
    createDate: string;
    reactions?: IEventGalleriaReaction[];
}
export interface IEventGalleriaReaction {
    uid: string;
    postedBy: string;
    postedByName: string;
    reactionType: ReactionTypes;
    reactionValue: any;
}
export enum ReactionTypes {
    VIEW = 'VIEW',
    VOTE = 'VOTE',
    COMMENT = 'COMMENT',
    LIKE = 'LIKE'
}
export interface IEventDate {
    second: number;
    milisecond: number;
}
export type ICalendarEvent = {
    id: string;
    allDay: boolean;
    color: string;
    textColor?: string;
    description: string;
    start: Date;
    end: Date;
    title: string;
};
