// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconCalendarEvent, IconMessages, IconArchive } from '@tabler/icons';

// constant
const icons = {
    IconCalendarEvent,
    IconMessages,
    IconArchive
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const events = {
    id: 'events',
    type: 'group',
    children: [
        {
            id: 'inscriptons',
            title: <FormattedMessage id="Open Inscriptions" />,
            type: 'item',
            url: '/inscriptions',
            icon: icons.IconCalendarEvent,
            breadcrumbs: false
        },
        {
            id: 'pollroom',
            title: <FormattedMessage id="Poll Room" />,
            type: 'item',
            url: '/polls',
            icon: icons.IconMessages,
            breadcrumbs: false
        },
        {
            id: 'archive',
            title: <FormattedMessage id="Archive Events" />,
            type: 'item',
            url: '/events/archives',
            icon: icons.IconArchive,
            breadcrumbs: false
        }
    ]
};

export default events;
