export enum FirestoreCollections { // eslint-disable-line
    USERS = 'users',
    EVENTS = 'events',
    DRAFT_EVENTS = 'event-drafs',
    INVITATIONS = 'invitations',
    NOTIFICATIONS = 'notifications',
    GALLERIA = 'galleria',
    REACTIONS = 'reactions',
    HOSTS = 'hosts'
}

/*
- events-drafts
- events
    -- hosts
    -- invitations
    -- assets
        ---reaction

        
- users
    - uid
    - displayName
    - lastLogin
    - avatar
    - authProvider
    -- events-invitations
    -- events-hosted
    -- notifications
*/
