// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';
import { DefaultRootStateProps } from 'types';
import { INotificationHookAction } from 'types/notifications';
// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['notifications'] = {
    forceRefresh: false,
    count: 0,
    hookAction: {} as INotificationHookAction
};

const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        init(state) {
            state = initialState;
        },
        forceReload(state, action) {
            state.forceRefresh = action.payload;
        },
        setCount(state, action) {
            state.count = action.payload;
        },
        setHookAction(state, action) {
            state.hookAction = action.payload;
        }
    }
});

// Reducer
export default notificationSlice.reducer;

// ----------------------------------------------------------------------

export function notificationForceReload() {
    return async () => {
        try {
            dispatch(notificationSlice.actions.forceReload(true));
        } catch (error) {
            console.log(error);
        }
    };
}
export function updateNotificationCount(newTotal: number) {
    return async () => {
        try {
            dispatch(notificationSlice.actions.setCount(newTotal));
        } catch (error) {
            console.log(error);
        }
    };
}
export function setHookAction(hookAction: INotificationHookAction) {
    return async () => {
        try {
            dispatch(notificationSlice.actions.setHookAction(hookAction));
        } catch (error) {
            console.log(error);
        }
    };
}
