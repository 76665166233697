// material-ui
import { Alert, Box, Button, Divider, Grid, Link, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IEvent, IEventSchedule } from 'types/event';
import '../create/style.scss';

import dayjs from 'dayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { IEventDetailScheduleProp } from '.';

dayjs.extend(localizedFormat);

interface Props {
    type: 'A' | 'B';
    justifyContent: 'flex-end' | 'flex-start';
    inSchedule: IEventDetailScheduleProp;
}

const EventDetailScheduleLite: React.FC<Props> = ({ type, justifyContent = 'flex-end', inSchedule }: Props) => {
    const [schedule, setSchedule] = useState({} as IEventSchedule);

    useEffect(() => {
        if (inSchedule) {
            const startSeconds = (inSchedule?.start?.seconds || 0) * 1000;
            const endSeconds = (inSchedule?.end?.seconds || 0) * 1000;

            const startDate = dayjs(startSeconds).format('ll');
            const startTime = dayjs(startSeconds).format('LT');
            const endDate = dayjs(endSeconds).format('ll');
            const endTime = dayjs(endSeconds).format('LT');

            const localSchedule = {
                startDate,
                startTime,
                endDate,
                endTime
            } as IEventSchedule;
            setSchedule(localSchedule);
        }
    }, [inSchedule]);

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                {type === 'A' && (
                    <Stack direction="row" spacing={0.3} className="schedule-note" sx={{ justifyContent }}>
                        <Typography sx={{ fontWeight: 'bold' }}>{schedule.startDate}</Typography>
                        <Typography>from</Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>{schedule.startTime}</Typography>
                        <Typography>until</Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>{schedule.endDate}</Typography>
                        <Typography>at</Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>{schedule.endTime}</Typography>
                    </Stack>
                )}

                {type === 'B' && (
                    <Stack>
                        <Stack component="div" direction="row" spacing={0.3} className="schedule-note" sx={{ justifyContent }}>
                            <Typography sx={{ fontWeight: 'bold' }}>{schedule.startDate}</Typography>
                            <Typography>@</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}>{schedule.startTime}</Typography>
                        </Stack>
                        <Stack direction="row" spacing={0.3} className="schedule-note" sx={{ justifyContent }}>
                            <Typography sx={{ fontWeight: 'bold' }}>{schedule.endDate}</Typography>
                            <Typography>@</Typography>
                            <Typography sx={{ fontWeight: 'bold' }}>{schedule.endTime}</Typography>
                        </Stack>
                    </Stack>
                )}
            </LocalizationProvider>
        </>
    );
};

export default EventDetailScheduleLite;
