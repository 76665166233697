import { NavItemType } from 'types';
import dashboard from './dashboard';
import events from './events';
import adminpanel from './adminpanel';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [dashboard, events, adminpanel]
};

export default menuItems;
