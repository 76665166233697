export interface NotificationsProps {
    forceRefresh: boolean;
    count: number;
    hookAction: INotificationHookAction;
}
export interface INotification {
    uid?: string;
    status: INotificationStatus;
    hookType: INotificationHookType;
    hookUid: string;
    caption: string;
    title: string;
    details?: string;
    creatAt: any;
}
export interface INotificationHookAction {
    uid?: string;
    body?: any;
}
export enum INotificationStatus {
    UNREAD = 'unread',
    READ = 'read',
    OTHER = 'other'
}
export enum INotificationHookType {
    NEW_EVENT_INVITATION = 'new-invitation',
    RESEND_INVITATION_EVENT = 'resend-invite-event',
    EVENT_CANCELLED = 'event-cancelled',
    EDIT_EVENT = 'edit-event',
    OPEN_INSCRIPTIONS = 'open-inscriptions',
    CLOSE_INSCRIPTIONS = 'close-inscriptions',
    OPEN_POLL = 'open-inscriptions',
    CLOSE_POLL = 'close-inscriptions',
    WINNER_ANNOUNCEMENT = 'winner-annoucement'
}
