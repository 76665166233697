// material-ui
import { useTheme } from '@mui/material/styles';
import { List } from '@mui/material';

// assets
import NotificationEvent from './NotificationEvent';
import { INotification, INotificationHookType } from 'types/notifications';

interface Props {
    notifications: INotification[];
}

const NotificationList: React.FC<Props> = ({ notifications }: Props) => {
    const theme = useTheme();

    return (
        <List
            sx={{
                width: '360px',
                maxWidth: 350,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 340
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {notifications.map((item) =>
                [
                    INotificationHookType.NEW_EVENT_INVITATION,
                    INotificationHookType.EDIT_EVENT,
                    INotificationHookType.RESEND_INVITATION_EVENT
                ].includes(item.hookType) ? (
                    item && <NotificationEvent inNotification={item} />
                ) : (
                    <></>
                )
            )}
        </List>
    );
};

export default NotificationList;
