// material-ui
import { Box, Button, Divider, Stack, Step, StepContent, StepLabel, Stepper } from '@mui/material';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'store';
import EventParcicipant from './event-participants';
import EventInfo from './event-info';
import './style.scss';
import EventPickType from './event-pick-type';
import EventInscriptionSchedule from './event-inscription-schedule';
import EventPollSchedule from './event-poll-schedule';
import { initPlanification, reloadEvents } from 'store/slices/event';
import { MdOutlineCelebration } from 'react-icons/md';
import { IEvent, IEventMember } from 'types/event';
import EventService from '../../../../../services/events.service';
import { notificationForceReload } from 'store/slices/notifications';
import { openSnackbar } from 'store/slices/snackbar';

interface Props {
    applyAction?: (action?: string) => void;
}
const EventCreate: React.FC<Props> = ({ applyAction }: Props) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.details);
    const event = useSelector((state) => state.event.planification);
    const [planification, setPlanification] = useState({} as IEvent);
    const [validStep, setValidStep] = useState(false);
    const [steps, setSteps] = useState([] as any[]);
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        setActiveStep(0);
        dispatch(initPlanification());
        setSteps(allSteps);
    }, []);
    useEffect(() => {
        setPlanification(event || ({} as IEvent));
    }, [event]);

    const nextStep = (current: any) => {
        setActiveStep(current + 1);
    };
    const onSelectType = (type: string) => {
        setActiveStep(1);
        if (type !== 'cheers-challenge') {
            setSteps(allSteps.filter((s) => s.index !== 3));
        } else {
            setSteps(allSteps);
        }
    };

    const prevStep = (current: any) => {
        setActiveStep(current - 1);
    };
    const updateActionStep = (isValid?: boolean) => {
        setValidStep(isValid || false);
    };
    const create = () => {
        const member = {
            uid: user?.uid,
            displayName: user?.displayName,
            avatar: user.avatar
        } as IEventMember;
        EventService.create({ ...planification, organizedBy: member }).then((result) => {
            Promise.all([dispatch(reloadEvents(true)), dispatch(notificationForceReload())]).then(() => {
                applyAction!('close');
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Event created successfully',
                        variant: 'alert',
                        alert: {
                            color: 'success',
                            variant: 'outlined'
                        },
                        close: true
                    })
                );
            });
        });
    };
    let allSteps = [
        {
            index: 0,
            name: 'What kind of event?',
            hideNavigation: true,
            component: <EventPickType actionHandle={onSelectType} />
        },
        {
            index: 1,
            name: 'Write the basic information',
            hideNavigation: false,
            component: <EventInfo updateActionStep={updateActionStep} />
        },
        {
            index: 2,
            name: 'Who will participate?',
            hideNavigation: false,
            component: <EventParcicipant updateActionStep={updateActionStep} />
        },
        {
            index: 3,
            name: 'Inscriptions Schedule will be',
            hideNavigation: false,
            component: <EventInscriptionSchedule updateActionStep={updateActionStep} />
        },
        {
            index: 4,
            name: 'Poll Schedule will be',
            hideNavigation: false,
            component: <EventPollSchedule updateActionStep={updateActionStep} />
        }
    ];
    return (
        <Box sx={{ width: '100%', margin: '20px' }}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((val, index) => (
                    <Step key={index}>
                        <StepLabel>{val.name}</StepLabel>
                        <StepContent>
                            <>
                                {val.component}
                                {!val.hideNavigation && (
                                    <>
                                        <Divider className="acton-divider" variant="middle" />
                                        <Stack direction="row" className="action-footer">
                                            {index > 0 && (
                                                <Button variant="contained" size="medium" onClick={() => prevStep(index)}>
                                                    BACK
                                                </Button>
                                            )}
                                            {index < steps.length - 1 && (
                                                <Button
                                                    variant="contained"
                                                    size="medium"
                                                    onClick={() => nextStep(index)}
                                                    disabled={!validStep}
                                                >
                                                    NEXT
                                                </Button>
                                            )}
                                            {val.index === steps.length - 1 && (
                                                <Button
                                                    variant="outlined"
                                                    size="medium"
                                                    onClick={create}
                                                    disabled={!validStep}
                                                    endIcon={<MdOutlineCelebration />}
                                                >
                                                    Let do this
                                                </Button>
                                            )}
                                        </Stack>
                                    </>
                                )}
                            </>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default EventCreate;
