// third-party
import { combineReducers } from 'redux';
import snackbarReducer from './slices/snackbar';
import userReducer from './slices/user';
import menuReducer from './slices/menu';
import eventReducer from './slices/event';
import notificationReducer from './slices/notifications';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    user: userReducer,
    menu: menuReducer,
    event: eventReducer,
    notification: notificationReducer
});

export default reducer;
