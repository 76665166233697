// material-ui
import { Avatar, Chip, Divider, Grid, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { IEvent, IEventSchedule } from 'types/event';
import dayjs from 'dayjs';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import notificationsService from 'services/notifications.service';
import { MdInsertInvitation, MdScheduleSend } from 'react-icons/md';
import { useDispatch, useSelector } from 'store';
import { useNavigate } from 'react-router-dom';
import { INotification, INotificationHookType, INotificationStatus } from 'types/notifications';
import snackbar, { openSnackbar } from 'store/slices/snackbar';
import { notificationForceReload, setHookAction } from 'store/slices/notifications';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

interface Props {
    inNotification: INotification;
}

const NotificationEvent: React.FC<Props> = ({ inNotification }: Props) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isNew, setIsNew] = useState<boolean>(false);
    const user = useSelector((state) => state.user.details);

    useEffect(() => {
        if (inNotification.creatAt) {
            const today = dayjs(new Date());
            const createdAt = dayjs(inNotification.creatAt.seconds * 1000).add(1, 'day');
            setIsNew(createdAt > today);
        }
    }, []);
    const hookNavigation = async () => {
        if (user.uid && inNotification.uid) {
            const res = await notificationsService.markAs(INotificationStatus.READ, user.uid, inNotification.uid).catch((error) => {
                console.log(error);
            });
            dispatch(
                setHookAction({
                    uid: inNotification.hookUid
                })
            );
            dispatch(notificationForceReload());
            if (
                [
                    INotificationHookType.NEW_EVENT_INVITATION,
                    INotificationHookType.RESEND_INVITATION_EVENT,
                    INotificationHookType.OPEN_INSCRIPTIONS
                ].includes(inNotification.hookType)
            ) {
                navigate('/participate');
            }
        }
    };

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        height: 28
    };

    return (
        <>
            <ListItemWrapper onClick={hookNavigation}>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                color: theme.palette.primary.dark,
                                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                                border: theme.palette.mode === 'dark' ? '1px solid' : 'none',
                                borderColor: theme.palette.primary.main
                            }}
                        >
                            {inNotification.hookType === INotificationHookType.NEW_EVENT_INVITATION && <MdInsertInvitation size="1.3rem" />}
                            {inNotification.hookType === INotificationHookType.RESEND_INVITATION_EVENT && <MdScheduleSend size="1.3rem" />}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">{inNotification.caption}</Typography>} />
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Typography variant="caption" display="block" gutterBottom>
                                    {dayjs(inNotification.creatAt?.seconds * 1000).fromNow()}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <Typography variant="subtitle2">{inNotification.title}</Typography>
                        <Typography variant="subtitle2">{inNotification.details}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {isNew && [INotificationStatus.READ].includes(inNotification.status) && (
                                <Grid item>
                                    <Chip label="read" sx={chipSX} />
                                </Grid>
                            )}
                            {isNew && [INotificationStatus.UNREAD].includes(inNotification.status) && (
                                <Grid item>
                                    <Chip label="new" sx={chipWarningSX} />
                                </Grid>
                            )}
                            {!isNew && ![INotificationStatus.READ].includes(inNotification.status) && (
                                <Grid item>
                                    <Chip label={inNotification.status} sx={chipErrorSX} />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper>
            <Divider />
        </>
    );
};
export default NotificationEvent;
