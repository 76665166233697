import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import EventCreate from 'views/pages/event-manager/event-detail/create';
import InscriptionDetails from 'views/pages/inscriptions/inscription-details';

// sample page routing
const Leaderboard = Loadable(lazy(() => import('views/pages/leaderboard')));
const AccountSettings = Loadable(lazy(() => import('views/pages/accountsettings')));
const EventManager = Loadable(lazy(() => import('views/pages/event-manager/event-list')));
const Inscriptions = Loadable(lazy(() => import('views/pages/inscriptions')));
const Polls = Loadable(lazy(() => import('views/pages/polls')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Leaderboard />
        },
        {
            path: '/leaderboard',
            element: <Leaderboard />
        },
        {
            path: '/inscriptions',
            element: <Inscriptions />
        },
        {
            path: '/participate',
            element: <InscriptionDetails />
        },
        {
            path: '/polls',
            element: <Polls />
        },
        {
            path: '/admin/events',
            element: <EventManager />
        },
        // {
        //     path: '/admin/events/edit',
        //     element: <EventEdit />
        // },
        {
            path: '/admin/events/create',
            element: <EventCreate />
        },
        {
            path: '/account-settings',
            breadcrumbs: false,
            element: <AccountSettings />
        }
    ]
};

export default MainRoutes;
