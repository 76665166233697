// types
import { ConfigProps } from 'types/config';

export const FIREBASE_API = {
    apiKey: 'AIzaSyAF1YIjCUfd-n5xvHGt32bbJ21CIaKt-bA',
    authDomain: 'cheers-a7a10.firebaseapp.com',
    projectId: 'cheers-a7a10',
    storageBucket: 'cheers-a7a10.appspot.com',
    messagingSenderId: '271216263360',
    appId: '1:271216263360:web:afd22128a5e9fd8e19ed25',
    measurementId: 'G-H28M2QERH9'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = 'leaderboard';

export const MAINTENANCE_MODE = true;

const config: ConfigProps = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'theme3', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: true
};

export default config;
