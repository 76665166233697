import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { IEvent, IEventAsset, IEventMember } from 'types/event';
import { v4 as uuidV4 } from 'uuid';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import UserService from './users.service';
import notificationsService from './notifications.service';
import { INotification, INotificationHookType } from 'types/notifications';
import { FirestoreCollections } from './collectionsNames';
import { FirestoreUserDetail } from 'types/user';

dayjs.extend(localizedFormat);

const db = firebase.firestore();
const storage = firebase.storage();

class EventService {
    public async getAll(): Promise<IEvent[]> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.EVENTS)
                .get()
                .then((snapshot) => {
                    const events: any = [];
                    snapshot.docs.map((doc) => {
                        events.push(doc.data() as IEvent);
                    });
                    resolve(events);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    public async get(eventId: string): Promise<IEvent> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.EVENTS)
                .doc(eventId)
                .get()
                .then(async (snapshot) => {
                    const event = snapshot.data() as IEvent;
                    const invitations = await this.getInvitations(eventId);
                    const host = await this.getHost(eventId);
                    resolve({ ...event, invitations, organizedBy: host });
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    public async getInvitations(eventId: string): Promise<IEventMember[]> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.EVENTS)
                .doc(eventId)
                .collection(FirestoreCollections.INVITATIONS)
                .get()
                .then((snapshot) => {
                    const data: IEventMember[] = [];
                    snapshot.docs.map((doc) => {
                        data.push(doc.data() as IEventMember);
                    });
                    resolve(data);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    public async getHost(eventId: string): Promise<IEventMember> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.EVENTS)
                .doc(eventId)
                .collection(FirestoreCollections.HOSTS)
                .get()
                .then((snapshot) => {
                    const data: IEventMember[] = [];
                    snapshot.docs.map((doc) => {
                        data.push(doc.data() as IEventMember);
                    });
                    resolve(data[0] || []);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    public async addInvitations(eventId: string, invitations: IEventMember[]): Promise<any> {
        return new Promise((resolve, reject) => {
            const batch = db.batch();
            invitations.map((member) => {
                const ref = db
                    .collection(FirestoreCollections.EVENTS)
                    .doc(eventId)
                    .collection(FirestoreCollections.INVITATIONS)
                    .doc(member.uid);
                batch.set(ref, member);
            });
            batch
                .commit()
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public async addHost(eventId: string, host: IEventMember): Promise<any> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.EVENTS)
                .doc(eventId)
                .collection(FirestoreCollections.HOSTS)
                .doc(host.uid)
                .set(host)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public async sendNotifications(event: IEvent, invitations: IEventMember[]): Promise<any> {
        return new Promise((resolve, reject) => {
            const notiValue = {
                caption: 'New Invitation',
                details: `You have been invited to a new ${event.type?.replace('-', ' ').toLocaleUpperCase()}`,
                title: `${event.title}`,
                hookType: INotificationHookType.NEW_EVENT_INVITATION,
                hookUid: event.uid
            } as INotification;
            notificationsService
                .create(invitations || ([] as IEventMember[]), notiValue)
                .then((notificationResult) => {
                    resolve(notificationResult);
                })
                .catch((errNotification) => {
                    reject(errNotification);
                });
        });
    }

    public async create(record: IEvent): Promise<any> {
        return new Promise((resolve, reject) => {
            const invitations = record.invitations as IEventMember[];
            const host = record.organizedBy as IEventMember;
            const uid = uuidV4();
            record = {
                ...record,
                uid
            };
            delete record.invitations;
            delete record.organizedBy;
            db.collection(FirestoreCollections.EVENTS)
                .doc(uid)
                .set(record)
                .then(async (result1) => {
                    const addInvitationsProc = this.addInvitations(uid, invitations);
                    const addUserEventRelationProc = UserService.addUsersEventRelation(uid, invitations);
                    const addNotificationProc = this.sendNotifications(record, invitations);
                    const addHostProc = this.addHost(uid, host);
                    const addHostRelationProc = UserService.addUsersHostRelation(uid, host);
                    Promise.all([addInvitationsProc, addUserEventRelationProc, addNotificationProc, addHostProc, addHostRelationProc]).then(
                        (result2: any[]) => {
                            resolve({
                                event: result1,
                                invitations: result2[0],
                                relations: result2[1],
                                notifications: result2[2],
                                hosts: result2[3],
                                hostRelation: result2[4]
                            });
                        }
                    );
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public async createAsDraft(record: IEvent): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log('draft record to insert', record);
            const uid = uuidV4();
            record = {
                ...record,
                uid,
                inscriptionStart: dayjs(record.inscriptionStart).toDate().toString(),
                inscriptionEnd: dayjs(record.inscriptionStart).toDate().toString(),
                pollStart: dayjs(record.inscriptionStart).toDate().toString(),
                pollEnd: dayjs(record.inscriptionStart).toDate().toString()
            };
            db.collection(FirestoreCollections.DRAFT_EVENTS)
                .doc(uid)
                .set(record)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public async getAllMembers(): Promise<any> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.USERS)
                .get()
                .then((snapshot) => {
                    const events: any = [];
                    snapshot.docs.map((doc) => {
                        events.push(doc.data() as IEventMember);
                    });
                    resolve(events);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    public async uploadAssetTask(user: FirestoreUserDetail, eventId: string, caption: string, imageAsFile: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const task = storage.ref(`/events/${eventId}/${user.uid!}`).put(imageAsFile);
            task.on(
                'state_changed',
                console.log,
                (err) => reject(err),
                () => {
                    storage
                        .ref(`/events/${eventId}`)
                        .child(user.uid!)
                        .getDownloadURL()
                        .then((fireBaseUrl) => {
                            this.addToGalleria(eventId, user.uid!, {
                                mediaSrc: fireBaseUrl,
                                caption,
                                postedBy: user.uid!,
                                postedByName: user.displayName!,
                                createDate: new Date().toDateString()
                            }).then((result) => {
                                resolve(result);
                            });
                        });
                }
            );
        });
    }

    public async addToGalleria(eventId: string, userId: string, asset: IEventAsset): Promise<any> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.EVENTS)
                .doc(eventId)
                .collection(FirestoreCollections.GALLERIA)
                .doc(userId)
                .set(asset)
                .then((result) => {
                    UserService.addUserAssetRelation(userId, eventId).then((result2) => {
                        resolve(result);
                    });
                })
                .then((error) => {
                    reject(error);
                });
        });
    }
}
export default new EventService();
