// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconUsers, IconTools, IconCalendarStats } from '@tabler/icons';

// constant
const icons = {
    IconUsers,
    IconTools,
    IconCalendarStats
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const adminpanel = {
    id: 'adminpanel',
    title: <FormattedMessage id="Admin Panel" />,
    type: 'group',
    children: [
        {
            id: 'event-manager',
            title: <FormattedMessage id="Event Manager" />,
            type: 'item',
            url: '/admin/events',
            icon: icons.IconCalendarStats
        },
        {
            id: 'users-manager',
            title: <FormattedMessage id="Users Manager" />,
            type: 'item',
            url: '/admin/users',
            icon: icons.IconUsers
        }
    ]
};

export default adminpanel;
