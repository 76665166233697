// third-party
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';
import { IEvent, IEventInviteType, IEventType } from 'types/event';
import dayjs from 'dayjs';
// types
import { DefaultRootStateProps } from 'types';
import EventService from '../../services/events.service';
// ----------------------------------------------------------------------

const emptyEvent = () => {
    const today = new Date().setHours(24, 0, 0, 0);
    const date = dayjs(today).format('YYYY-MM-DD');
    const defaultInscriptionStart = dayjs(`${date} 08:00:00 AM`);
    // const defaultInscriptionEnd = defaultInscriptionStart.add(1, 'day').add(1, 'minutes');
    const defaultInscriptionEnd = dayjs(`${dayjs(defaultInscriptionStart).add(1, 'd').format('YYYY-MM-DD')} 08:01:00 AM`);
    const defaultPollStart = defaultInscriptionStart.add(2, 'day');
    const defaultPollEnd = dayjs(`${dayjs(defaultPollStart).format('YYYY-MM-DD')} 05:00:00 PM`);

    return {
        uid: '-1',
        title: '',
        type: IEventType.NONE,
        more: '',
        inscriptionStart: defaultInscriptionStart,
        inscriptionEnd: defaultInscriptionEnd,
        pollStart: defaultPollStart,
        pollEnd: defaultPollEnd,
        scheduleVoting: false,
        inviteType: IEventInviteType.FULLHOUSE,
        inscriptionsEndsAfter: 24,
        invitations: []
    } as IEvent;
};
const initialState: DefaultRootStateProps['event'] = {
    selection: undefined,
    planification: {} as IEvent,
    reloadEvents: false
};

const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        init(state) {
            state = initialState;
        },
        initPlanification(state) {
            state.planification = emptyEvent();
        },
        setSelection(state, action) {
            state.selection = action.payload;
        },
        updatePlanification(state, action) {
            state.planification = action.payload;
        },
        reloadEvents(state, action) {
            state.reloadEvents = action.payload;
        }
    }
});

// Reducer
export default eventSlice.reducer;

// ----------------------------------------------------------------------

export function reload(uid: String) {
    return async () => {
        try {
            // debugger; // eslint-disable-line
            if (uid !== '-1' && uid.length > 3) {
                const response = await EventService.get(uid.trim());
                dispatch(eventSlice.actions.setSelection(response));
            }
        } catch (error) {
            console.log(error);
        }
    };
}
export function createNewEvent() {
    return async () => {
        try {
            dispatch(eventSlice.actions.init());
        } catch (error) {
            console.log(error);
        }
    };
}
export function initPlanification() {
    return async () => {
        try {
            dispatch(eventSlice.actions.initPlanification());
        } catch (error) {
            console.log(error);
        }
    };
}
export function updatePlanification(event: IEvent) {
    return async () => {
        try {
            dispatch(eventSlice.actions.updatePlanification(event));
        } catch (error) {
            console.log(error);
        }
    };
}
export function reloadEvents(needReload: boolean) {
    return async () => {
        try {
            dispatch(eventSlice.actions.reloadEvents(needReload));
        } catch (error) {
            console.log(error);
        }
    };
}
