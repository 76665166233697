// material-ui
import { Avatar, Chip, Divider, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import eventsService from 'services/events.service';
import { useDispatch, useSelector } from 'store';
import { IEvent, IEventAsset } from 'types/event';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import AvatarMembers from 'utils/avatar-members';
import FileUploader from 'utils/file-uploader';
import './styles.scss';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import InscriptionCountdown from './inscriptions-countdown';
import { IEventDetailScheduleProp } from 'views/pages/event-manager/event-detail/edit';
import EventDetailScheduleLite from 'views/pages/event-manager/event-detail/edit/event-detail-schedule-lite';
import EmojiButtonPicker from 'ui-component/EmojiButtonPicker';
import LabelIcon from 'ui-component/LabelIcon';
import { MdOutlinePoll } from 'react-icons/md';
import { HiOutlineCamera, HiOutlineClock, HiOutlineExclamation, HiOutlineUsers } from 'react-icons/hi';
import { IconSpeakerphone } from '@tabler/icons';
import { FiClock, FiSend } from 'react-icons/fi';
import { openSnackbar } from 'store/slices/snackbar';
import { useNavigate } from 'react-router-dom';

dayjs.extend(localizedFormat);

const InscriptionDetails = () => {
    // const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.user.details);
    const hookId = useSelector((state) => state.notification.hookAction.uid);
    const [event, setEvent] = useState<IEvent>({} as IEvent);
    const [inscriptionSchedule, setInscriptionSchedule] = useState({} as IEventDetailScheduleProp);
    const [pollSchedule, setPollSchedule] = useState({} as IEventDetailScheduleProp);
    const [isLocked, setIsLocked] = useState<boolean>(true);
    const [inscriptions, setInscriptions] = useState({} as any);
    const [caption, setCaption] = useState('');
    const [file, setFile] = useState<any>(null);

    useEffect(() => {
        if (hookId) {
            fetchEvent(hookId);
        }
    }, []);
    useEffect(() => {
        if (hookId) {
            fetchEvent(hookId);
        }
    }, [hookId]);

    const onEmojiClick = (emojiObject: any) => {
        setCaption(`${caption}${emojiObject.emoji}`);
    };

    const fetchEvent = (eventId: string) => {
        eventsService.get(eventId).then((eventResult) => {
            console.log(eventResult);
            const start = dayjs(eventResult.inscriptionStart.seconds * 1000).toDate();
            const end = dayjs(eventResult.inscriptionEnd.seconds * 1000).toDate();
            const today = dayjs(new Date());
            console.log(dayjs(start).diff(today, 'm', true));
            if (dayjs(start).diff(today, 'm', true) > 0) {
                setIsLocked(true);
            } else {
                setIsLocked(false);
            }
            setInscriptionSchedule({
                start: eventResult.inscriptionStart,
                end: eventResult.inscriptionEnd,
                minDate: dayjs(new Date())
            });

            setPollSchedule({
                start: eventResult.pollStart,
                end: eventResult.pollEnd,
                minDate: dayjs(eventResult.inscriptionEnd?.seconds)
            });
            setInscriptions({
                start,
                end
            });
            console.log({ start, end });
            setEvent(eventResult);
        });
    };
    const lockInscriptions = (arg: any) => {};

    const onFileUpload = (argFile: any) => {
        setFile(argFile);
    };
    const addParticipation = () => {
        if (user.uid && event.uid) {
            eventsService.uploadAssetTask(user, event.uid, caption, file).then((uploadResult) => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Thanks for your participation',
                        variant: 'alert',
                        alert: {
                            color: 'success',
                            variant: 'outlined'
                        },
                        close: true
                    })
                );
            });
            navigate('/leaderboard');
        }
    };

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} sx={{ marginBottom: '5px' }}>
                <Typography key="page-title" className="page-title">
                    Inscription Time!
                </Typography>
            </Stack>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={5}>
                    <Stack component="div" className="left-panel">
                        <MainCard>
                            <Grid container direction="row" rowSpacing={1}>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={0.5}>
                                        <Typography className="event-subtext">Event</Typography>
                                        <Typography
                                            sx={{ textTransform: 'capitalize' }}
                                            className="event-subtext-bold"
                                        >{`/ ${event.type?.replace('-', ' ')}`}</Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography className="event-text">{event.title?.toLocaleUpperCase()}</Typography>
                                </Grid>
                                <Grid item lg={12} md={12} display={{ xs: 'none', lg: 'block' }}>
                                    <Typography className="event-subtitle justified" sx={{ minHeight: '170px' }}>
                                        {event.more}
                                    </Typography>
                                    <Stack direction="row" spacing={0.8} sx={{ marginTop: '10px' }}>
                                        <Avatar
                                            sizes="small"
                                            sx={{ width: 18, height: 18 }}
                                            src={event.organizedBy?.avatar}
                                            alt={event.organizedBy?.displayName}
                                        />
                                        <Typography className="event-subtext" sx={{ fontSize: '12px !important' }}>
                                            {' Organized by '}
                                        </Typography>
                                        <Typography className="event-subtext-bold" sx={{ fontSize: '12px !important' }}>
                                            {event.organizedBy?.displayName}
                                        </Typography>
                                    </Stack>
                                    <Divider sx={{ height: '10px', borderBottomWidth: 2, marginBottom: '12px' }} />
                                </Grid>
                                <Grid item xs={4}>
                                    <LabelIcon icon={<IconSpeakerphone />} label="Stage" />
                                </Grid>
                                <Grid item xs={8}>
                                    {isLocked && <Chip icon={<HiOutlineExclamation />} color="warning" label="Event not started" />}
                                    {!isLocked && <Chip icon={<HiOutlineClock />} label="Open Inscriptions" />}
                                </Grid>
                                <Grid item xs={4}>
                                    {!isLocked && <LabelIcon icon={<FiClock />} label="Open until" />}
                                    {isLocked && <LabelIcon icon={<FiClock />} label="Event Start" />}
                                </Grid>
                                <Grid item xs={8} className="countdown-widget">
                                    {!isLocked && <InscriptionCountdown endDate={inscriptions.end} onComplete={lockInscriptions} />}
                                    {isLocked && <InscriptionCountdown endDate={inscriptions.start} onComplete={lockInscriptions} />}
                                </Grid>
                                <Grid item xs={4}>
                                    <LabelIcon icon={<HiOutlineCamera />} label="Inscriptions" />
                                </Grid>
                                <Grid item xs={8}>
                                    <EventDetailScheduleLite type="B" justifyContent="flex-start" inSchedule={inscriptionSchedule} />
                                </Grid>
                                <Grid item xs={4}>
                                    <LabelIcon icon={<HiOutlineUsers />} label="Invites" />
                                </Grid>
                                <Grid item xs={8}>
                                    <AvatarMembers
                                        members={event?.invitations}
                                        total={3}
                                        width="24px"
                                        height="24px"
                                        align="left"
                                        emptyMessage=""
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <LabelIcon icon={<MdOutlinePoll />} label="Poll" />
                                </Grid>
                                <Grid item xs={8}>
                                    <EventDetailScheduleLite type="B" justifyContent="flex-start" inSchedule={pollSchedule} />
                                </Grid>
                            </Grid>
                        </MainCard>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={6} lg={7} spacing={1}>
                    <MainCard sx={{ marginTop: '0px' }}>
                        <Stack>
                            <FileUploader
                                disabled={isLocked}
                                size={{ width: '100%', height: '400px !important' }}
                                callback={onFileUpload}
                            />
                            <Stack direction="row" alignItems="flex-start" justifyContent="flex-start">
                                <TextField
                                    sx={{ marginTop: '10px' }}
                                    id="caption"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    value={caption}
                                    variant="outlined"
                                    placeholder="What do you share with us?"
                                    onChange={(e) => setCaption(e.target.value)}
                                    disabled={isLocked || false}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" className="toolbox">
                                                <EmojiButtonPicker disabled={isLocked} callback={onEmojiClick} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end" className="actionbox">
                                                <IconButton disabled={!file || caption.length <= 0} onClick={addParticipation}>
                                                    <FiSend />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Stack>
                        </Stack>
                    </MainCard>
                </Grid>
            </Grid>
        </>
    );
};

export default InscriptionDetails;
