import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { IEventMember } from 'types/event';
import { FirestoreUserDetail } from 'types/user';
import { FirestoreCollections } from './collectionsNames';

const db = firebase.firestore();
const storage = firebase.storage();

class UserService {
    public async register(record: any): Promise<any> {
        return new Promise((resolve, reject) => {
            record.lastLogin = new Date();
            db.collection(FirestoreCollections.USERS)
                .doc(record.uid)
                .set(record)
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public async get(userId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.USERS)
                .doc(userId)
                .get()
                .then((result) => {
                    const user = result.data() as any;
                    user.displayName = `${user.name}  ${user.lname}`;
                    resolve(user);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public async getUserEvents(userId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.USERS)
                .doc(userId)
                .collection(FirestoreCollections.EVENTS)
                .get()
                .then((snapshot) => {
                    const events: any = [];
                    snapshot.docs.map((doc) => {
                        events.push(doc.data() as any);
                    });
                    resolve(events);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public async getUserHosts(userId: any): Promise<any> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.USERS)
                .doc(userId)
                .collection(FirestoreCollections.HOSTS)
                .get()
                .then((snapshot) => {
                    const hosts: any = [];
                    snapshot.docs.map((doc) => {
                        hosts.push(doc.data() as any);
                    });
                    resolve(hosts);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public async setLastlogin(uid: any): Promise<any> {
        return new Promise((resolve, reject) => {
            db.collection('users')
                .doc(uid)
                .update({
                    lastLogin: new Date()
                })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public async updateAllUserDataOnRelations(userId: string) {
        return new Promise((resolve, reject) => {
            Promise.all([this.get(userId), this.getUserEvents(userId), this.getUserHosts(userId)]).then((results: any[]) => {
                const lastUser = results[0] as FirestoreUserDetail;
                const events = results[1] as any[];
                const hosts = results[2] as any[];
                const batch = db.batch();
                events.map((eventId) => {
                    const participants = db
                        .collection(FirestoreCollections.EVENTS)
                        .doc(eventId)
                        .collection(FirestoreCollections.INVITATIONS)
                        .doc(userId);
                    batch.update(participants, { displayName: lastUser.displayName, avatar: lastUser.avatar });
                });
                hosts.map((hostEventId) => {
                    const hostRef = db
                        .collection(FirestoreCollections.EVENTS)
                        .doc(hostEventId)
                        .collection(FirestoreCollections.HOSTS)
                        .doc(userId);
                    batch.update(hostRef, { displayName: lastUser.displayName, avatar: lastUser.avatar });
                });
                batch
                    .commit()
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        });
    }

    public async updateAvatarOnRelations(userId: string, avatar: string) {
        return new Promise((resolve, reject) => {
            Promise.all([this.getUserEvents(userId), this.getUserHosts(userId)]).then((results: any[]) => {
                const batch = db.batch();
                const events = results[0] as any[];
                events.map((event) => {
                    const participants = db
                        .collection(FirestoreCollections.EVENTS)
                        .doc(`${event.uid}`)
                        .collection(FirestoreCollections.INVITATIONS)
                        .doc(`${userId}`);
                    console.log(participants);
                    batch.update(participants, { avatar });
                });

                const hosts = results[1] as any[];
                hosts.map((hostEventId) => {
                    const hostRef = db
                        .collection(FirestoreCollections.EVENTS)
                        .doc(hostEventId.uid)
                        .collection(FirestoreCollections.HOSTS)
                        .doc(userId);
                    batch.update(hostRef, { avatar });
                });

                batch
                    .commit()
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        });
    }

    public async updateAvatar(userId: any, avatarUrl: string): Promise<any> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.USERS)
                .doc(userId)
                .update({
                    avatar: avatarUrl
                })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public async uploadAvatarTask(userId: any, imageAsFile: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const task = storage.ref(`/avatars/${userId}`).put(imageAsFile);
            task.on(
                'state_changed',
                console.log,
                (err) => reject(err),
                () => {
                    storage
                        .ref('avatars')
                        .child(userId)
                        .getDownloadURL()
                        .then((fireBaseUrl) => {
                            const updateAvatarProc = this.updateAvatar(userId, fireBaseUrl);
                            const updateAvatarRelationsProc = this.updateAvatarOnRelations(userId, fireBaseUrl);
                            const updateAvatarEventHostProc = this.updateAvatarOnRelations(userId, fireBaseUrl);
                            Promise.all([updateAvatarProc, updateAvatarRelationsProc]).then((results) => {
                                resolve(fireBaseUrl);
                            });
                        });
                }
            );
        });
    }

    public async addUsersEventRelation(eventId: string, participants: IEventMember[]): Promise<any> {
        return new Promise((resolve, reject) => {
            const batch = db.batch();
            participants.map((p) => {
                const ref = db.collection(FirestoreCollections.USERS).doc(p.uid).collection(FirestoreCollections.EVENTS).doc(eventId);
                batch.set(ref, { uid: eventId });
            });
            batch
                .commit()
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public async addUsersHostRelation(eventId: string, host: IEventMember): Promise<any> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.USERS)
                .doc(host.uid)
                .collection(FirestoreCollections.HOSTS)
                .doc(eventId)
                .set({ uid: eventId })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public async addUserAssetRelation(userId: string, eventId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.USERS)
                .doc(userId)
                .collection(FirestoreCollections.GALLERIA)
                .doc(eventId)
                .set({ uid: eventId })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
export default new UserService();
