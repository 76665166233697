// material-ui
import { Stack, Typography } from '@mui/material';

interface Props {
    icon: any;
    label: string;
}
const LabelIcon: React.FC<Props> = ({ icon, label }: Props) => {
    return (
        <>
            <Stack direction="row" spacing={1}>
                <Typography variant="caption" sx={{ fontSize: '22px', fontWeight: 'lighter' }}>
                    {icon}
                </Typography>
                <Typography variant="caption" sx={{ fontSize: '16px' }}>
                    {label}
                </Typography>
            </Stack>
        </>
    );
};

export default LabelIcon;
