// material-ui
import { Box, Link, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IEvent, IEventType } from 'types/event';
import './style.scss';
import { MdCelebration } from 'react-icons/md';
import { FaVoteYea } from 'react-icons/fa';
import { RiQuestionnaireLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'store';
import { updatePlanification } from 'store/slices/event';
import MainCard from 'ui-component/cards/MainCard';

interface Props {
    actionHandle: ({}: any) => void;
}

const EventPickType: React.FC<Props> = ({ actionHandle }: Props) => {
    const dispatch = useDispatch();
    const event = useSelector((state) => state.event.planification) as IEvent;
    const [eventType, setEventType] = useState('');

    useEffect(() => {
        if (event?.type) {
            setEventType(event.type);
        }
    }, []);

    const onSelectType = (type: IEventType) => {
        const update = {
            ...event,
            type
        };
        actionHandle(type);
        dispatch(updatePlanification(update));
    };
    const types = [
        {
            type: IEventType.CHEERSCHALLENGE,
            icon: <MdCelebration />,
            title: 'Cheers Challenge',
            sub: 'The invited memebers upload a content so that they themselves vote for the one they liked the most.',
            enabled: true
        },
        {
            type: IEventType.NOMINATOIN,
            icon: <FaVoteYea />,
            title: 'Nominations',
            sub: 'Invited members choose among other members',
            enabled: true
        },
        {
            type: IEventType.TAKEDESITION,
            icon: <RiQuestionnaireLine />,
            title: 'Take a desition',
            sub: 'the admin needs to make a decision, the members vote on a content provided by the admin',
            enabled: false
        }
    ];
    return (
        <>
            <Stack className="main-box" direction="row" justifyContent="left" spacing={1.5}>
                {types.map((item, index) => (
                    <Link
                        key={index}
                        component="button"
                        disabled={!item.enabled}
                        className={`type-box ${eventType === item.type ? 'selected' : ''}`}
                        onClick={() => onSelectType(item.type)}
                    >
                        <MainCard className={`type-box-content ${!item.enabled ? 'disabled' : ''}`}>
                            <Stack alignItems="center">
                                <Typography variant="h2" className={`type-icon ${!item.enabled ? 'disabled' : ''}`}>
                                    {item.icon}
                                </Typography>
                                <Typography variant="h2" className={`text ${!item.enabled ? 'disabled' : ''}`}>
                                    {item.title}
                                </Typography>
                                <Typography className={`text sub ${!item.enabled ? 'disabled' : ''}`}>{item.sub}</Typography>
                            </Stack>
                        </MainCard>
                    </Link>
                ))}
            </Stack>
        </>
    );
};

export default EventPickType;
