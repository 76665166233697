// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconTrophy } from '@tabler/icons';

// constant
const icons = {
    IconTrophy
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'leaderboard',
            title: <FormattedMessage id="leaderboard" />,
            type: 'item',
            url: '/leaderboard',
            icon: icons.IconTrophy,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
