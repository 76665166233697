// third-party
import { createSlice } from '@reduxjs/toolkit';

// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';

// project imports
import { dispatch } from '../index';
// types
import { DefaultRootStateProps } from 'types';
import usersService from '../../services/users.service';
import { FirestoreUserDetail } from 'types/user';
// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['user'] = {
    error: null,
    details: {} as FirestoreUserDetail
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateDetails(state, action) {
            state.details = action.payload;
        },
        updateAvatar(state, action) {
            state.details.avatar = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function UpdateUserDetails(uid: any) {
    return async () => {
        try {
            const response = await usersService.get(uid);
            dispatch(slice.actions.updateDetails(response));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function UpdateUserAvatar(uid: any, file: any) {
    return async () => {
        try {
            const response = await usersService.uploadAvatarTask(uid, file);
            console.log('update avatar response');
            console.log(response);
            dispatch(slice.actions.updateAvatar(response));
        } catch (error) {
            console.log(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
