// material-ui
import { Avatar, Badge, Box, Link, Paper, styled, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import './styles.scss';

interface Props {
    endDate: Date;
    onComplete: (arg: any) => void;
}

const InscriptionCountdown: React.FC<Props> = ({ endDate, onComplete }: Props) => {
    const [value, setValue] = useState(new Date());
    useEffect(() => {
        if (endDate) {
            setValue(endDate);
        }
    }, []);
    useEffect(() => {
        if (endDate) {
            setValue(endDate);
        }
    }, [endDate]);

    return (
        <>
            <Countdown
                key={value.toString()}
                date={value}
                onComplete={(arg) => onComplete(arg)}
                renderer={(props) => (
                    <>
                        <Box component="span" className="countdown-box">
                            {props.formatted.days}
                            <Box component="span" className="title-box">
                                days
                            </Box>
                        </Box>
                        <Box component="span" className="countdown-box">
                            {props.formatted.hours}
                            <Box component="span" className="title-box">
                                hours
                            </Box>
                        </Box>
                        <Box component="span" className="countdown-box">
                            {props.formatted.minutes}
                            <Box component="span" className="title-box">
                                min
                            </Box>
                        </Box>
                        <Box component="span" className="countdown-box">
                            {props.formatted.seconds}
                            <Box component="span" className="title-box">
                                sec
                            </Box>
                        </Box>
                    </>
                )}
            />
        </>
    );
};

export default InscriptionCountdown;
