// material-ui
import { Avatar, AvatarGroup, Link, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import '../assets/scss/cheers-global.scss';
import { IEventMember } from 'types/event';

// project imports

// ==============================|| SAMPLE PAGE ||============================== //
interface Props {
    members?: IEventMember[] | any[] | undefined;
    max?: number | 3;
    total?: number;
    align?: 'left' | 'right';
    emptyMessage: string | '';
    title?: string | 'participants';
    width: string;
    height: string;
}

const AvatarMembers: React.FC<Props> = ({ members, max, title, align, total, width, height }: Props) => {
    const [alignItems, setAlignItems] = useState<string>();
    useEffect(() => {
        init();
        console.log(members);
    }, []);
    useEffect(() => {
        init();
    }, [align]);
    const init = () => {
        console.log(align);
        setAlignItems(align && align === 'left' ? 'flex-start' : 'flex-end');
    };
    const stringAvatar = (name?: string) => {
        if (!name?.length) return {};
        name = name
            .split(' ')
            .filter((n) => n)
            .join(' ');
        return {
            alt: name,
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
        };
    };

    return (
        <>
            {members && members.length > 0 && (
                <Stack direction="row" justifyContent={alignItems} alignItems={alignItems}>
                    <AvatarGroup total={total}>
                        {members.map((participant, index) =>
                            participant.avatar ? (
                                <Avatar key={index} alt={participant.displayName} src={participant.avatar} sx={{ width, height }} />
                            ) : (
                                <Avatar key={index} {...stringAvatar(participant.displayName)} sx={{ width, height, fontSize: '14px' }} />
                            )
                        )}
                    </AvatarGroup>
                    {members.length > total! && <Typography className="memberTotal">{`+ ${members.length - 3} members`}</Typography>}
                </Stack>
            )}
            {!members || (members.length === 0 && <Typography>(emptyMessage)</Typography>)}
        </>
    );
};

export default AvatarMembers;
