// material-ui
import { Avatar, Badge, Button, IconButton, Link, Paper, Popover, styled, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import '../assets/scss/cheers-global.scss';
import AnimateButton from 'ui-component/extended/AnimateButton';
import Picker from 'emoji-picker-react';
import * as bs from 'react-icons/bs';

interface Props {
    disabled?: boolean;
    callback: (emojiObject: any) => void;
}

const EmojiButtonPicker: React.FC<Props> = ({ disabled, callback }: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const onEmojiClick = (event0: any, emojiObject: any) => {
        callback(emojiObject);
    };

    return (
        <>
            <IconButton disabled={disabled} aria-label="emoji picker" component="span" onClick={handleClick}>
                <bs.BsEmojiLaughing />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <Picker onEmojiClick={onEmojiClick} pickerStyle={{ width: '300px' }} />
            </Popover>
        </>
    );
};

export default EmojiButtonPicker;
