import { useRoutes } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import MaintenanceRoutes from './MaintenanceRoutes';
import { MAINTENANCE_MODE } from '../config';

// ==============================|| ROUTING RENDER ||============================== //
const Maintenance = Loadable(lazy(() => import('views/pages/maintenance/under-construction')));

let routes = [] as any;
if (!MAINTENANCE_MODE) {
    routes = [MainRoutes, LoginRoutes, AuthenticationRoutes];
} else {
    routes = [{ path: '/', element: <Maintenance /> }, MaintenanceRoutes];
}

export default function ThemeRoutes() {
    return useRoutes(routes);
}
