import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import { IEvent, IEventMember } from 'types/event';
import { v4 as uuidV4 } from 'uuid';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { INotification, INotificationStatus } from 'types/notifications';
import HelperService from './helper.service';
import { FirestoreCollections } from './collectionsNames';

dayjs.extend(localizedFormat);

const db = firebase.firestore();

class NotificationsService {
    public async getAll(userId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.USERS)
                .doc(userId)
                .collection(FirestoreCollections.NOTIFICATIONS)
                .get()
                .then((snapshot) => {
                    const events: any = [];
                    snapshot.docs.map((doc) => {
                        events.push(doc.data() as IEvent);
                    });
                    resolve(HelperService.sortByField(events, 'creatAt'));
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    public async get(userId: string, notificationId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.USERS)
                .doc(userId)
                .collection(FirestoreCollections.NOTIFICATIONS)
                .doc(notificationId)
                .get()
                .then((snapshot) => {
                    resolve(snapshot.data() as IEvent);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }

    public async markAs(status: INotificationStatus, userId: string, notificationId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            db.collection(FirestoreCollections.USERS)
                .doc(userId)
                .collection(FirestoreCollections.NOTIFICATIONS)
                .doc(notificationId)
                .update({
                    status
                })
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public async markAllAs(status: INotificationStatus, userId: string, notificationIds: string[]): Promise<any> {
        return new Promise((resolve, reject) => {
            console.log('inside mark all as');
            const batch = db.batch();
            notificationIds.map((notificationId) => {
                const ref = db
                    .collection(FirestoreCollections.USERS)
                    .doc(userId)
                    .collection(FirestoreCollections.NOTIFICATIONS)
                    .doc(notificationId);
                batch.update(ref, { status });
            });
            batch
                .commit()
                .then((result) => {
                    console.log(result);
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    public async create(recipientsMembers: IEventMember[], record: INotification): Promise<any> {
        return new Promise((resolve, reject) => {
            const batch = db.batch();
            const uid = uuidV4();
            recipientsMembers.map((member) => {
                const ref = db
                    .collection(FirestoreCollections.USERS)
                    .doc(member.uid)
                    .collection(FirestoreCollections.NOTIFICATIONS)
                    .doc(uid);
                batch.set(ref, {
                    ...record,
                    uid,
                    status: INotificationStatus.UNREAD,
                    creatAt: new Date(firebase.firestore.Timestamp.now().seconds * 1000)
                });
            });
            batch
                .commit()
                .then((result) => {
                    resolve(result);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
export default new NotificationsService();
