import * as _ from 'lodash';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

class HelperService {
    public sortByField(array: any[], sortField: string): any[] {
        return _.sortBy(array, (obj) => {
            return dayjs(obj.creatAt?.seconds * 1000).toDate();
        }).reverse();
    }
}
export default new HelperService();
