// material-ui
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IEvent, IEventSchedule } from 'types/event';
import MainCard from 'ui-component/cards/MainCard';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { useDispatch, useSelector } from 'store';
import { updatePlanification } from 'store/slices/event';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Alert from '@mui/material/Alert';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

export interface DisplayDates {
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
}
interface Props {
    updateActionStep: (valid?: boolean) => void;
}

const EventInscriptionSchedule: React.FC<Props> = ({ updateActionStep }: Props) => {
    const dispatch = useDispatch();
    const event = useSelector((state) => state.event.planification) as IEvent;
    const [schedule, setSchedule] = useState({} as IEventSchedule);
    const [lastValid, setLastValid] = useState({} as IEventSchedule);
    const [dateFormat, setDateFormat] = useState({} as DisplayDates);
    const [error, setError] = useState('');

    useEffect(() => {
        const startDate = dayjs(event.inscriptionStart).format('YYYY-MM-DD');
        const startTime = dayjs(event.inscriptionStart).format('HH:mm:ss');
        const endDate = dayjs(event.inscriptionEnd).format('YYYY-MM-DD');
        const endTime = dayjs(event.inscriptionEnd).format('HH:mm:ss');
        const localSchedule = {
            duration: 1,
            startDate,
            startTime,
            endDate,
            endTime
        } as IEventSchedule;
        setSchedule(localSchedule);
        setLastValid({ ...localSchedule });
    }, []);
    useEffect(() => {
        console.log('event', event);
    }, [event]);
    useEffect(() => {
        reCalculate();
    }, [schedule]);
    const reCalculate = () => {
        // debugger; // eslint-disable-line
        console.log(schedule);
        const startDate = dayjs(`${schedule.startDate} ${schedule.startTime}`);
        let endDate = dayjs(startDate.add(schedule.duration, 'day'));
        if (schedule.duration === -1) {
            endDate = dayjs(`${schedule.endDate} ${schedule.endTime}`);
        }
        const newFormat = {
            startDate: startDate.format('LL'),
            startTime: startDate.format('LT'),
            endDate: endDate.format('LL'),
            endTime: endDate.format('LT')
        };
        setDateFormat(newFormat);
        // debugger; // eslint-disable-line
        const newPollDate = dayjs(endDate).add(1, 'day');
        const newPollEndDate = newPollDate.add(1, 'day');

        dispatch(
            updatePlanification({
                ...event,
                inscriptionStart: startDate.toDate(),
                inscriptionEnd: endDate.toDate(),
                pollStart: newPollDate.toDate(),
                pollEnd: newPollEndDate.toDate()
            })
        );
    };
    const startDateHandle = (startDate: string) => {
        onValidateChange({
            ...schedule,
            startDate
        });
    };
    const startTimeHandle = (startTime: string) => {
        onValidateChange({
            ...schedule,
            startTime
        });
    };
    const endDateHandle = (endDate: string) => {
        onValidateChange({
            ...schedule,
            endDate
        });
    };
    const endTimeHandle = (endTime: string) => {
        onValidateChange({
            ...schedule,
            endTime
        });
    };
    const onValidateChange = (temp: IEventSchedule) => {
        const current = dayjs(new Date());
        const startDate = dayjs(`${temp.startDate} ${temp.startTime}`);
        const endDate = dayjs(`${temp.endDate} ${temp.endTime}`);
        let duration = schedule.duration;
        let errorStr = '';
        if (startDate < current || endDate < current || endDate <= startDate) {
            console.log('c', startDate.toDate());
            console.log('s', startDate.toDate());
            console.log('e', endDate.toDate());
            errorStr = `The range of the inscription process must be established within a future date to the current date`;
        } else if (startDate > endDate) {
            errorStr = `The date range for the inscription process is invalid`;
        }

        if (errorStr.length > 0) {
            updateActionStep(false);
            duration = -1;
            setError(errorStr);
        } else {
            setError('');
            updateActionStep(true);
        }
        const newValues = {
            duration,
            startDate: temp.startDate,
            startTime: temp.startTime,
            endDate: temp.endDate,
            endTime: temp.endTime
        };
        setSchedule(newValues);
    };
    const undo = (e: any) => {
        e.preventDefault();
        onValidateChange(lastValid);
    };

    return (
        <>
            <MainCard className="main-box" sx={{ height: schedule.duration === -1 ? '240px' : '180px' }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <TextField
                                id="date"
                                label="When Start"
                                type="date"
                                value={schedule.startDate}
                                onChange={(e) => startDateHandle(e.target.value)}
                                fullWidth
                                InputProps={{
                                    inputProps: {
                                        min: dayjs(`${schedule.startDate} ${schedule.startTime}`),
                                        max: dayjs(`${schedule.endDate} ${schedule.endTime}`)
                                    }
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    required: true
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                id="time"
                                label="Time"
                                type="time"
                                value={schedule.startTime}
                                onChange={(e) => startTimeHandle(e.target.value)}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Duration</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={schedule.duration || 1}
                                    label="Duration"
                                    onChange={(newValue) => {
                                        const newDuration = newValue.target.value as number;
                                        setSchedule({
                                            ...schedule,
                                            duration: newDuration
                                        });
                                        console.log(' on duration value was changed');
                                    }}
                                >
                                    <MenuItem value={1}>1 day</MenuItem>
                                    <MenuItem value={2}>2 days</MenuItem>
                                    <MenuItem value={3}>3 days</MenuItem>
                                    <MenuItem value={-1}>Custom</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        {schedule.duration === -1 && (
                            <>
                                <Divider />
                                <Grid item xs={4}>
                                    <TextField
                                        id="date"
                                        label="Deadline"
                                        type="date"
                                        value={schedule.endDate}
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={(e) => endDateHandle(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="time"
                                        label="Time"
                                        type="time"
                                        value={schedule.endTime}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={(e) => endTimeHandle(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </>
                        )}
                        {error.length === 0 && (
                            <Grid item xs={12}>
                                <Alert severity="info">
                                    <Stack direction="row" spacing={0.3} className="schedule-note">
                                        <Typography>Inscriptions will take place on the</Typography>
                                        <Typography sx={{ fontWeight: 'bold' }}>{dateFormat.startDate}</Typography>
                                        <Typography>from</Typography>
                                        <Typography sx={{ fontWeight: 'bold' }}>{dateFormat.startTime}</Typography>
                                        <Typography>until</Typography>
                                        <Typography sx={{ fontWeight: 'bold' }}>{dateFormat.endDate}</Typography>
                                        <Typography>at</Typography>
                                        <Typography sx={{ fontWeight: 'bold' }}>{dateFormat.endTime}</Typography>
                                    </Stack>
                                </Alert>
                            </Grid>
                        )}
                        {error.length > 0 && (
                            <Grid item xs={12}>
                                <Alert
                                    severity="error"
                                    action={
                                        <Button color="inherit" size="small" onClick={(e) => undo(e)}>
                                            UNDO
                                        </Button>
                                    }
                                >
                                    {error}
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                </LocalizationProvider>
            </MainCard>
        </>
    );
};

export default EventInscriptionSchedule;
