// material-ui
import { Link, Paper, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import '../assets/scss/cheers-global.scss';
import { useDropzone } from 'react-dropzone';
import { IoMdImages } from 'react-icons/io';
import { BiHappyBeaming } from 'react-icons/bi';

const fileTypes = ['JPG', 'PNG', 'GIF'];
interface Props {
    size?: Size;
    disabled?: boolean;
    callback?: (binaryStr?: string | ArrayBuffer) => void;
}
interface Size {
    width: string;
    height: string;
}

const FileUploader: React.FC<Props> = ({ size, disabled, callback }: Props) => {
    const [files, setFiles] = useState([] as any[]);

    useEffect(() => {}, []);

    const onDrop = useCallback((acceptedFiles: any[]) => {
        const temp: any[] = [];
        acceptedFiles.forEach((f) => {
            temp.push(f);
            const reader = new FileReader();

            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');
            reader.onload = () => {
                const binaryStr = reader.result;
                callback?.(binaryStr!);
            };
            reader.readAsArrayBuffer(f);
        });
        setFiles(temp);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, disabled });

    return (
        <>
            <div>
                {files.length === 0 && (
                    <Paper className="cc-drop-box" sx={{ width: size?.width, height: size?.height }}>
                        {!disabled && (
                            <Stack alignItems="center" justifyContent="center" className="placeholder" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Typography className="icon">
                                    <IoMdImages />
                                </Typography>
                                {isDragActive ? (
                                    <Typography variant="subtitle1">Drop the files here ...</Typography>
                                ) : (
                                    <Typography>
                                        {' '}
                                        Drop your files(s) here or <Typography component={Link}>browse</Typography>
                                    </Typography>
                                )}
                            </Stack>
                        )}
                        {disabled && (
                            <Stack alignItems="center" justifyContent="center" className="placeholder-empty">
                                <BiHappyBeaming />
                                <Typography>{`${"We'll see each other soon, when it's time for the event"}`}</Typography>
                            </Stack>
                        )}
                    </Paper>
                )}
                {files.length > 0 &&
                    files.map((file) => (
                        <Stack className="preview-media-holder">
                            <img src={file ? URL.createObjectURL(file) : ''} alt={file ? file.name : null} />
                        </Stack>
                    ))}
            </div>
        </>
    );
};

export default FileUploader;
